import {graphql} from 'gatsby';
import {Trans} from "gatsby-plugin-react-i18next";
import React from 'react';
import {Helmet} from 'react-helmet';
import styled from 'styled-components';
import Layout from '../components/layout';
import SpecialBg from "../images/nigeria/special-bg.png";

const PrivacyPolicy = () => {
    return (
        <Layout pageTitle="Privacy Policy | Moniepoint ">
            <Helmet>
                <link rel="alternate" href="https://moniepoint.com/ng/privacy-policy" hreflang="x-default"/>
                <link rel="alternate" href="https://moniepoint.com/ng/privacy-policy" hreflang="en-ng"/>
            </Helmet>
            <GetStartedContainer>
                <div className="bg-mask"></div>
                <div className="content">
                    <div className="get-started-section">
                        <h2 className={`title`}>
                            <Trans>Privacy Policy</Trans>
                        </h2>
                    </div>
                </div>
            </GetStartedContainer>
            <ContentContainer>
                <div className="content">
                    <div>
                        <div className="pp-list-outer">
                            <p className="pp-list-copy top">This privacy policy (“Policy”) applies to the website(s) and
                                mobile application(s) (hereinafter referred to as, the "Sites'') provided by Moniepoint
                                Inc. and its subsidiaries (collectively referred to as “Moniepoint”, “we”, “us”, “our”),
                                and other products/services of Moniepoint. This Policy discloses our data protection
                                practices on our Sites, products and subscriber-based services (“Services'’), inclusive
                                of the type of personal data that we collect, our method of collection of personal data,
                                use of personal data and procedures for sharing personal data with third parties.
                            </p>
                            <p className="pp-list-copy">The Sites covered by this Policy include our existing websites,
                                mobile applications and all other additional websites and mobile applications produced
                                and managed by Moniepoint Inc. <a href="https://www.moniepoint.com" target="_blank"
                                                                  rel="noopener noreferrer">www.moniepoint.com</a>
                            </p>
                            <p className="pp-list-copy">We value the trust you place in us and understand that your
                                privacy is of utmost importance to you. In light of this, we make use of the highest
                                standards to ensure secure transactions and the privacy of customer information.</p>
                            <p className="pp-list-copy">By visiting the Sites (including all websites and mobile
                                applications which may be added or removed from time to time) you agree to the terms and
                                conditions of this Privacy Policy. If you do not want your personal data processed in
                                accordance with this policy, please do not use or access the Sites or the Services. </p>
                            <p className="pp-list-copy">We reserve the right, at our sole discretion, to alter and
                                update this Policy from time to time. We therefore invite you to review the current
                                version of this Policy each time you return to the Sites.</p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">ELIGIBILITY</h1>
                            <p className="pp-list-copy">The Sites and Services are intended solely for persons who, if
                                they are natural persons, are eighteen (18) years of age or older, and any registration
                                by, use of or access to the Sites and Services by any natural person under eighteen (18)
                                is unauthorised, unlicensed and in violation of this Policy. </p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">CONSENT</h1>
                            <p className="pp-list-copy">By using the Sites, Services and by providing your personal
                                data, you consent to the collection and use of the information you disclose to us in
                                accordance with this Policy, including but not limited to your consent for sharing your
                                personal data in line with the terms contained in this Policy. If we decide to change
                                this Policy, we will post those changes on this page so that you are always aware of
                                what information we collect, how we use it and under what circumstances we disclose it.
                                If you do not agree to give consent to the use of personal data as described in this
                                Policy, please do not use or access the Sites or Services.</p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">LAWFUL BASIS FOR COLLECTING AND PROCESSING PERSONAL DATA</h1>
                            <p className="pp-list-copy">In accordance with the provisions of the NDPR, prior to the
                                processing of personal data there must be in existence a legal basis for such
                                processing. In compliance with the provisions of the NDPR, we process your personal data
                                in line with the following legal basis:
                                <ul className="pp-list inner">
                                    <li className=""><b>Consent</b>: where you have consented to our processing of your
                                        personal data for one or more specific reasons. Such consent is given by you
                                        through your continuous use of the Services and the Sites.
                                    </li>
                                    <li><b>Performance of a contract</b>: in order to perform a contract we have with
                                        you or a contract to which you are a party to and in order to take necessary
                                        steps at your request prior to entering into such a contract.
                                    </li>
                                    <li><b>Legal obligation</b>: where processing of personal data is required by law.
                                        We are required by law to retain certain account opening information and
                                        personal data of our customers beyond the date such customers cease to carry on
                                        business with us.
                                    </li>
                                    <li><b>Legitimate interest</b>: in order to protect the vital interests of other
                                        data subjects, and in order to carry out the purposes of our business. In
                                        addition to this, we have a legitimate interest to prevent fraud, money
                                        laundering and to verify identity of data subjects, in order to protect our
                                        customers and business, to understand how people interact with our Sites, to
                                        provide communication which we think will be of interest to you and to determine
                                        the effectiveness of promotional campaigns and advertising.
                                    </li>
                                    <li><b>Public interest</b>: such processing is necessary for the performance of a
                                        task carried out in the interest of the public on in exercise of an official
                                        public mandate vested on us.
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">INFORMATION WE MAY COLLECT FROM YOU</h1>
                            <p className="pp-list-copy">When you use the Sites or Services, we collect and store your
                                personal data which is provided by you from time to time.</p>
                            <p className="pp-list-copy">Personal data/ information in this context shall include all
                                data such as: any means of information relating to an identified or identifiable natural
                                person who can be identified by:
                                <ul className="pp-list inner">
                                    <li>a name;</li>
                                    <li>an identification number;</li>
                                    <li>location data, an online identifier;</li>
                                    <li>address, a photo, an email address;</li>
                                    <li>bank details;</li>
                                    <li>posts on social networking websites; and</li>
                                    <li>other unique identifiers such as but not limited to MAC address, IP address,
                                        IMEI number, IMSI number, SIM.
                                    </li>
                                </ul>
                            </p>
                            <p className="pp-list-copy">This is also applies to personal data/ information regarded as
                                sensitive which could include:
                                <ul className="pp-list inner">
                                    <li>data relating to religious or other beliefs;</li>
                                    <li>race, ethnicity;</li>
                                    <li>political views;</li>
                                    <li>trade union membership,</li>
                                    <li>criminal records; or</li>
                                    <li>any other sensitive personal information.</li>
                                </ul>
                            </p>
                            <p className="pp-list-copy">For the purpose of accessing our Services, the personal data we
                                may collect include: your full legal names, marital status, title, date of birth,
                                gender, business name, email address, mailing address, telephone number, bank account
                                number, payment card details, bank verification number, national identification number,
                                international passport number, means of identification, guarantors contact details, bank
                                statements, usernames, password, your preferences, interests, feedback and survey
                                responses, preference in receiving marketing information from us and our third parties
                                and your communication preferences, etc. </p>
                            <p className="pp-list-copy">Our primary goal in collecting the above stated personal data is
                                to provide you with a safe, efficient, smooth and customised experience. This allows us
                                to provide Services and features that most likely meet your needs, and to customise the
                                Sites to make your experience safer and easier. </p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">HOW WE COLLECT INFORMATION</h1>
                            <p className="pp-list-copy">We collect information you provide directly to us, for example,
                                we collect information when you register or log on to the Sites, create an account,
                                subscribe to a Service, participate in any interactive features on our Services, fill
                                out a form, take part in surveys, post on our message boards, upload any documentation,
                                request customer support, make an enquiry, communicate with us by email, phone or post,
                                interact with us on social media, etc.</p>
                            <p className="pp-list-copy">We will also collect your information where you partially
                                complete and/or abandon any information inputted in the Sites and may use this
                                information to contact you to remind you to complete any outstanding information.</p>
                            <p className="pp-list-copy">Every computer connected to the internet is given a domain name
                                and a set of numbers that serve as that computer’s internet protocol “IP address”. When
                                you use the Sites, our web servers automatically recognize your domain name and IP
                                address. The domain name and IP address reveals nothing personal about you other than
                                the IP address from which you have accessed the Sites. We are able to see information
                                relating to your browsing patterns and technical data about the equipment you use to
                                access the website through the use of cookies, server logs and other similar
                                technologies. You can select your preference from the cookies settings on any of our
                                websites.</p>
                            <p className="pp-list-copy">We may also collect technical data from third parties/ public
                                sources such as analytics providers, advertising networks, search information providers.
                                We may obtain contact, financial and transaction data from providers of technical,
                                payment, credit referencing and delivery services based both inside and outside Nigeria.
                                We utilise third-party service providers to secure information related to financial
                                crime, fraud, sanctions and politically exposed persons.</p>
                            <p className="pp-list-copy">We do not own personal data provided and will only store such
                                data for a period reasonably needed and we will do our best to ensure that such personal
                                data is secured against all foreseeable hazards and breaches such as theft,
                                cyber-attack, viral attack, unauthorised dissemination, manipulation of any kind, damage
                                by rain, fire or exposure to other natural elements. </p>
                            <p className="pp-list-copy">We will not sell, share, transfer or rent out any personal
                                information to others in ways different from what is disclosed in this Policy, and our
                                terms and conditions of use. We may share generic information not linked to any personal
                                identification information regarding visitors and users with our business partners,
                                trusted affiliates and advertisers.</p>
                            <p className="pp-list-copy">
                                <h1 className="pp-list-header"> Providing us with information about others</h1>
                            </p>

                            <p className="pp-list-copy">If you provide us with personal data about someone else, you are
                                responsible for ensuring that you have provided the required notices and have obtained
                                the individual’s explicit consent to provide us with the personal data and that you
                                explain to them how we collect, use, disclose and retain their personal data or direct
                                them to read our Policy.</p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">INFORMATION WE COLLECT FROM OTHER SOURCES</h1>
                            <p className="pp-list-copy">In order to provide you with access to the Services, or to
                                provide you with better service in general, we may combine information obtained from
                                other sources (for example, a third-party developer whose application you have
                                authorised) and combine that with information we collect through the Sites.</p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">HOW WE USE YOUR PERSONAL DATA</h1>
                            <p className="pp-list-copy">The purpose of collecting your personal data is to give you an
                                efficient, enjoyable, secure and seamless customer experience.</p>
                            <p className="pp-list-copy">We may use your personal data for the following purposes:
                                <ul className="pp-list inner">
                                    <li>To provide the requested Services and support to you;</li>
                                    <li>To process transactions and send notices about your transactions to requisite
                                        parties;
                                    </li>
                                    <li>To verify your identity;</li>
                                    <li>To resolve disputes and troubleshoot problems;</li>
                                    <li>To manage risk, detect, prevent, and/or remediate fraud or other potentially
                                        prohibited or illegal activities;
                                    </li>
                                    <li>To detect, prevent or remediate violations of policies or applicable user
                                        agreements;
                                    </li>
                                    <li>To improve our services by implementing aggregate customer preferences;</li>
                                    <li>To manage and protect our information technology infrastructure;</li>
                                    <li>To contact you at any time through your provided telephone number, email address
                                        or other contact details;
                                    </li>
                                    <li>To notify you about activities on your account, troubleshoot problems with your
                                        account and collect fees or monies owed;
                                    </li>
                                    <li>To monitor traffic patterns and usage of the Sites to help to improve the Sites
                                        design and layout;
                                    </li>
                                    <li>To record and store communications made via phone, skype or the website chat
                                        function;
                                    </li>
                                    <li>To personalise your experience on our Sites or communications/advertising;</li>
                                    <li>To provide customer service, including to respond to your enquiries and fulfill
                                        any of your requests for information;
                                    </li>
                                    <li>To send you important information regarding the services and/or other technical
                                        notices, updates, security alerts, support and administrative messages;
                                    </li>
                                    <li>To poll your opinions through surveys or questionnaires; and</li>
                                    <li>As Moniepoint  believes to be necessary or appropriate:
                                        <ul className="pp-list inner sub">
                                            <li>To comply with a legal obligation. This applies where the processing is
                                                necessary for Moniepoint  to comply with the law;
                                            </li>
                                            <li>To enforce or apply this Policy; and</li>
                                            <li>To protect Moniepoint ’s legitimate interests, privacy, property or
                                                safety, and/or those of a third party as long as your rights do not
                                                override those interests.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </p>
                            <p className="pp-list-copy">We may monitor and record our communications with you, including
                                e-mails and phone conversations for training, quality assurance purposes, and to meet
                                our legal and regulatory obligations in general. </p>
                        </div>

                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">Fraud prevention</h1>
                            <p className="pp-list-copy">We may process your personal data on the basis that we have a
                                legitimate interest to prevent fraud and money laundering, and to verify your identity,
                                in order to protect our business and to comply with laws that apply to us. Such
                                processing is also a contractual requirement of the services you have requested.</p>
                            <p className="pp-list-copy">We may carry out fraud prevention checks using a fraud
                                prevention database. If false or inaccurate information is provided, and/or fraud is
                                identified, details will be passed to the Central Bank of Nigeria and the Economic and
                                Financial Crimes Commission. Additionally, law enforcement agencies may access and use
                                this information.</p>
                        </div>

                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">Data analytics and bench-marking</h1>
                            <p className="pp-list-copy">We may use information generated and stored during your use of
                                our Services for our legitimate activities to enable us to give you the best service
                                and/or solutions and the best experience. These purposes include to:
                                <ul className="pp-list inner">
                                    <li>deliver advertising or information to you which may be useful to you, based on
                                        your use of preferences;
                                    </li>
                                    <li>carry out research and development to improve our Services;</li>
                                    <li>develop and provide new and existing functionality and Services (including
                                        statistical analysis, benchmarking and forecasting Services); and
                                    </li>
                                    <li>provide you with location-based Services (for example location relevant content)
                                        where we collect geo-location data to provide a relevant experience.
                                    </li>
                                </ul>
                            </p>
                            <p className="pp-list-copy">Whenever we use your information for our legitimate interests,
                                we will ensure that your information is processed on a pseudonymised basis and displayed
                                at aggregated levels, which will not be linked back to you or to any living
                                individual.</p>
                            <p className="pp-list-copy">You have the right to object to processing based on our
                                legitimate activities but if you object, this may affect our ability to provide certain
                                Services and/or solutions for your benefit.</p>
                        </div>

                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">YOUR RIGHTS AS A DATA SUBJECT</h1>
                            <p className="pp-list-copy">Your personal data is protected by legal rights enshrined in the
                                NDPR. These rights include the following:
                                <ul className="pp-list inner">
                                    <li>the right to be told how we use your personal data and obtain access to your
                                        personal data;
                                    </li>
                                    <li>the right to have your personal data rectified or erased or place restrictions
                                        on processing your personal data;
                                    </li>
                                    <li>the right to object to the processing of your personal data e.g. where the
                                        processing is based on our legitimate interests. Please note that this may
                                        prevent us from continuing to provide Services to you;
                                    </li>
                                    <li>the right to have any information you provided to us on an automated basis
                                        returned to you in a structured, commonly used and machine-readable format, or
                                        sent directly to another organisation, where technically feasible (“data
                                        portability”);
                                    </li>
                                    <li>where the processing of your personal data is based on your consent, the right
                                        to withdraw that consent subject to legal or contractual restrictions;
                                    </li>
                                    <li>the right to object to any decisions based on the automated processing of your
                                        personal data, including profiling; and
                                    </li>
                                    <li>the right to lodge a complaint with the supervisory authority responsible for
                                        data protection matters.
                                    </li>
                                </ul>
                            </p>
                            <p className="pp-list-copy">Please note that if you request for a copy of your personal
                                data, you may be required to pay a fee.</p>
                            <p className="pp-list-copy">If you would like to exercise any of the above stated rights,
                                please follow the following procedures:
                                <ul className="pp-list inner">
                                    <li>put your request in writing and send it to us through your usual registered
                                        channel (e.g. by registered email).
                                    </li>
                                    <li>specify the right you wish to exercise.</li>
                                    <li>You can also access the Data Subject Access Request (DSAR) portal on our
                                        website.
                                    </li>
                                </ul>
                            </p>
                            <p className="pp-list-copy">For more information or to exercise your data protection rights
                                please, please contact our Data Protection Officer at <a
                                    href="mailto:dpo@moniepoint.com" rel="noopener noreferrer">dpo@moniepoint.com.</a>
                            </p>
                            <p className="pp-list-copy">We will endeavour to process all subject access requests within
                                thirty (30) days and if any further extension is required, we will communicate same
                                through existing consented channels – at no cost. However, please note that you may
                                continue to receive existing communications for a transitional period whilst we update
                                your preferences.</p>
                        </div>


                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">RETENTION OF YOUR DATA</h1>
                            <p className="pp-list-copy">We will not retain your personal data for longer than is
                                necessary for the purposes for which such personal data is processed. This means that
                                your personal data will only be retained for as long as it is still required to provide
                                you with the Services or is necessary for legal reasons. When calculating the
                                appropriate retention period of your personal data we consider the nature and
                                sensitivity of the personal data, the purposes for which we are processing such personal
                                data, and any applicable statutory/regulatory retention periods. Using these criteria,
                                we regularly review the personal data that we hold and the purposes for which such is
                                held and processed. Our Payment Card Industry Data Security Standard (“PCIDSS”)
                                obligation means that we are obliged to retain personal data for a minimum of ten (10)
                                years from the end date of our business relationship with you. </p>
                            <p className="pp-list-copy">
                                When we determine that personal data can no longer be retained (or where you request
                                that we delete your personal data in accordance with your rights) we ensure that such
                                personal data is securely deleted, anonymized or destroyed. However, please note that,
                                in some circumstances we may decide to retain your personal data as may be reasonably
                                necessary. In such circumstances, we will anonymize your personal data before retaining
                                same.
                            </p>

                            <p className="pp-list-copy">On at least a quarterly basis, we systematically remove and
                                destroy all cardholder data that has exceeded its retention period, and review and
                                ensure the remaining stored cardholder data remains within the formal retention
                                requirements. </p>
                            <p className="pp-list-copy">
                                Wherever the primary account number (“PAN”) is stored, whether electronically or on
                                paper, it is masked. The first six and last four digits are the maximum number of digits
                                that may be displayed. Certain members of the operations and Service delivery units have
                                a legitimate business need when dealing with customer/cardholder enquiries to access the
                                PAN. Wherever the PAN is stored (including in logs, removable media, etc.), it is made
                                unreadable by means of one-way hashes. Cardholder data is never stored on removable
                                media and when removable physical storage media (including documents, faxes, and
                                electronic media) are no longer required (i.e. they have passed their retention
                                periods), they are destroyed.
                            </p>

                        </div>

                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">ACCURACY OF YOUR DATA</h1>
                            <p className="pp-list-copy">It is important that the personal data Moniepoint holds about
                                you is accurate and current. Please keep Moniepoint informed if any aspect of your
                                personal data changes at any time during your relationship with us. On our
                                customer-facing products, you can easily update your personal data yourself or
                                alternatively contact our Data Protection Officer via <a
                                    href="mailto:dpo@moniepoint.com"
                                    rel="noopener noreferrer">dpo@moniepoint.com.</a> when
                                you want to exercise your right of rectification.</p>
                        </div>

                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">SECURITY OF YOUR DATA</h1>
                            <p className="pp-list-copy">
                                In order to protect your personal data, we have put in place appropriate organisational
                                and technical security measures. These measures include storing data on a dedicated and
                                secure server with at least 256-bit encryption, restricting access to your personal data
                                to certain employees, ensuring that our internal information technology systems are
                                suitably secure, and implementing procedures to deal with any suspected data breach.
                            </p>
                            <p className="pp-list-copy">
                                In the unlikely event of a data breach, Moniepoint will take steps to mitigate any loss
                                or destruction of data and, if appropriate, will notify you and any applicable authority
                                of such a breach.

                            </p>
                        </div>

                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">DATA TRANSFERS AND SHARING</h1>
                            <p className="pp-list-copy">
                                Due to the fact that we operate in a regulated environment, we cannot ensure that all
                                your private communications and other personally identifiable information will never be
                                disclosed in ways not otherwise described in this policy. By way of example (without
                                limiting the foregoing), we may be forced to disclose information to the government,
                                regulatory bodies, law enforcement agencies, and third parties for the performance of a
                                task carried out in the interest of the public, for the protection of your vital
                                interest, for the performance of a contract which you are a party to and also where you
                                have expressly given us written consent to disclose same.

                            </p>
                            <p className="pp-list-copy">
                                We may need to pass your information to third party service providers which maintain,
                                administer or develop the sites on our behalf and the information will only be provided
                                for such limited purposes and as detailed below. Additionally, we may provide aggregate
                                statistics about our customers, sales, traffic patterns and related website information
                                to reputable third-parties, but these statistics will include no personally identifiable
                                information.
                            </p>
                            <p className="pp-list-copy">Moniepoint may transfer your personal data to third parties
                                of the following types:
                                <ul className="pp-list inner">
                                    <li>companies providing identity or financial validation services;</li>
                                    <li>financial product providers;</li>
                                    <li>payment services companies acting on your, or our behalf;</li>
                                    <li>banks;</li>
                                    <li>companies providing analytics services;</li>
                                    <li>data, service and software providers;</li>
                                    <li>companies collecting and publishing customer reviews;</li>
                                    <li>marketing services companies; and</li>
                                    <li>Regulatory and law enforcement bodies.</li>
                                </ul>
                            </p>
                            
                            <p className="pp-list-copy">A few of our identity verification Third Party Providers collect your personal data via our Sites through the use of Apple Inc. 's (“Apple”) TrueDepth Application Programming Interface (“TrueDepth API”). As a result of the integration of our Sites with such Third Party Providers, our Sites make use of automatically collected information using the device camera on your Apple mobile device and the TrueDepth API provided by Apple.</p>
                            <p className="pp-list-copy">The use of your personal data collected as a result of this is to track your facial features, and control the augmented reality (AR) experience. We use ARKit to capture your face 3D spatial orientation and facial expressions. In doing this, we use this data to ensure that the picture (selfie) being taken is of a live user for authentication and fraud reduction purposes. The ARKit information is processed entirely locally and the spatial orientation/facial expression data is not submitted to any third (or first) parties. None of the information collected by the TrueDepth API ever leaves your mobile device nor is it persistently stored on the device. </p>
                            <p className="pp-list-copy">
                                However, we will ensure to obtain your written consent, or notify you of requests for
                                your personal information from the government, regulatory bodies, law enforcement
                                agencies, and third parties for the performance of a task carried out in the interest of
                                the public, for the protection of your vital interest, for the performance of a contract
                                which you are a party to and also where you have expressly given us written consent to
                                disclose same.

                            </p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">CONFIDENTIALITY</h1>
                            <p className="pp-list-copy">
                                You further acknowledge that the sites may contain information which is designated
                                confidential by us and that you shall not disclose such information without our prior
                                written consent. Your information is regarded as confidential and therefore will not be
                                divulged to any third party, unless if legally required to do so to the appropriate
                                authorities. We will not sell, share, or rent your personal data to any third party nor
                                use your email address for unsolicited mail. Any emails sent by us will only be in
                                connection with the provision of agreed Services.
                            </p>
                            <p className="pp-list-copy">We take all necessary precautions to protect your personal
                                information both online and off-line. It is important for you to protect against
                                unauthorised access to your password, your mobile phone or computer. Be sure to log off
                                from the Sites when you are using a shared phone/computer. We also protect your personal
                                data off-line. Access to your personal data is limited to employees, agents or partners
                                and third parties with whom we are working who we reasonably believe will need that
                                information to provide the Services to you. </p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">COOKIES</h1>
                            <p className="pp-list-copy">We use data collection devices such as ‘cookies’ on certain
                                pages of the Sites. Cookies are small files stored on your hard drive that assist us in
                                providing Services customised to your requirements and tastes. We also offer certain
                                features that are only available through the use of a ‘cookie’. Cookies can also help us
                                provide information, which is targeted to your interests. Cookies may be used whether
                                you choose to register with Us or not.</p>
                            <p className="pp-list-copy">We also use cookies to allow you to enter your password less
                                frequently during a session. Most cookies are ‘session cookies’, meaning that they are
                                automatically deleted from your hard drive at the end of a session. You are always free
                                to decline our cookies if your browser permits, although in that case you may not be
                                able to use certain features on the Sites and you may be required to re-enter your
                                password more frequently during a session. A cookie cannot read data off your hard disk
                                or read cookie files created by other sites. Use of a cookie is in no way linked to any
                                personally identifiable information while on the Sites. Once you close your browser, the
                                cookie simply terminates. For instance, by setting a cookie on your browser, you would
                                not have to log in a password more than once, thereby saving time while on the
                                Sites.</p>
                            <p className="pp-list-copy">You can choose whether to accept cookies by changing the
                                settings of your browser. You can reset your browser to refuse all cookies or allow your
                                browser to show you when a cookie is being sent. If you reject the cookies on the Sites,
                                you may still be able to use the Sites, but it shall be limited to certain minimal
                                functionality. The only drawback to this is that you may be limited to some areas of
                                Sites or limited to certain functions of the Sites.</p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">JOB APPLICANTS</h1>
                            <p className="pp-list-copy">If you apply for a job at Moniepoint , you will be asked to
                                submit information to Moniepoint  such as your name, contact details, information
                                about your education and work history and any other background information that might be
                                relevant to your application or that you choose to share with us. If you do not provide
                                this information to us, we might not be able to process your application.</p>
                            <p className="pp-list-copy">We will use this information to assess your application and
                                candidacy for the position you have applied for. Without limiting the generality of the
                                foregoing, this may include:
                                <ul className="pp-list inner">
                                    <li>Evaluating your skills and experience in relation to the qualification required
                                        for the job you have applied for; and
                                    </li>
                                    <li>Tracking feedback and interactions, we have with you throughout the recruiting
                                        process. We may also use your information when analysing our internal
                                        recruitment processes to determine:
                                        <ul className="pp-list inner sub">
                                            <li>Which recruitment sources to focus on;</li>
                                            <li>How to improve integration and training programs for new hires with
                                                different educational backgrounds and work experience; and
                                            </li>
                                            <li>How to enhance our interview model to improve hiring quality.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </p>
                            <p className="pp-list-copy">The information you provide may also be used to communicate with
                                you about Moniepoint  events and to send you publications that we think may be of
                                interest to you.</p>
                            <p className="pp-list-copy">We might share your personal data with other companies in our
                                group as well as with third parties such as recruitment service providers, background
                                check providers and information technology system providers. These Moniepoint group
                                companies and third parties might be located in a different country than your country of
                                residence.</p>
                            <p className="pp-list-copy">We will keep your application data for up to one (1) year. If
                                you no longer wish for Moniepoint  to process your personal data or if you wish to
                                exercise any of your rights as a data subject, please contact <a
                                    href="mailto:dpo@moniepoint.com" rel="noopener noreferrer">dpo@moniepoint.com.</a>
                            </p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">DISCLAIMER</h1>
                            <p className="pp-list-copy">We will keep your information secure by taking appropriate
                                technical and organisational measures against its unauthorised or unlawful processing
                                and against its accidental loss, destruction or damage. We will do our best to protect
                                your personal data, but we cannot guarantee the security of your personal data which is
                                transmitted to other websites via an internet or similar connection. If we have given
                                you (or you have chosen) a password to access certain areas of the Sites please keep
                                this password safe, we will not share this password with anyone.</p>
                            <p className="pp-list-copy">As a user of the Services, you understand and agree that you
                                assume all responsibility and risk attached to safeguarding your account with us. You
                                shall at no time whatsoever disclose your password to anyone, nor shall you allow anyone
                                make use of your account.</p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">UPDATES TO THE PRIVACY POLICY</h1>
                            <p className="pp-list-copy">We are constantly trying to improve our Sites and Services, so
                                we may need to change this Policy from time to time as well. We will alert you of
                                material changes by, for example, placing a notice on our websites and/or by sending you
                                an email (if you have registered your e-mail details with us) when we are required to do
                                so by applicable law. We reserve the right to update this Policy as we deem fit, from
                                time to time, without any intimation to you and your continued use of the Sites will
                                signify your acceptance of any amendment to these terms.</p>
                            <p className="pp-list-copy">Our updated terms will also be displayed on our websites. It is your responsibility to check this
                                Privacy Policy from time to time to verify such updates. </p>
                            <p className="pp-list-copy">If you believe at any time that we have not handled your
                                personal data in accordance with this Policy, please contact our Data Protection
                                Officer.</p>
                            <p className="pp-list-copy">We have appointed a Data Protection Officer (DPO) who is
                                responsible for dealing with all such concerns, in addition to overseeing questions
                                relating to this Policy and handling requests in relation to the exercise of your
                                rights. If you have any concerns or questions, please contact the Data Protection
                                Officer using the details set out below.</p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">CONTACT</h1>
                            <p className="pp-list-copy">
                                <b>Data Protection Officer</b><br/>
                                Moniepoint Inc.<br/>
                                241, Southwark Bridge Road,<br/>
                                SE1 6FP<br/>
                                United Kingdom<br/>
                                <a href="mailto:dpo@moniepoint.com" rel="noopener noreferrer">dpo@moniepoint.com</a>
                            </p>
                            <p className="pp-list-copy top">If you have any questions, comments and requests regarding
                                your privacy and rights, please let us know how we can help.</p>
                            <p className="pp-list-copy top">LastLast Updated: 10th August 2023.</p>
                        </div>
                    </div>
                </div>
            </ContentContainer>
        </Layout>
    )
}

export default PrivacyPolicy


export const query = graphql`
query {
    locales: allLocale(
      filter: { ns: { in: ["common", "nigeria"] }, language: { eq: "en" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const GetStartedContainer = styled.section`
  background: rgba(3, 87, 238, 1);
  background-size: fill;
  background-repeat: no-repeat;
  background-position: 50% 25%;
  color: white;
  position: relative;

  .bg-mask {
    background: url(${SpecialBg});
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: inherit;
    opacity: 0.4;
  }

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 81px 151px 101px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 81px 60px 101px;
    }

    .get-started-section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-family: "Founders Grotesk";
        font-size: 58px;
        font-weight: 500;
        line-height: 66px;
        letter-spacing: 0em;
        text-align: center;
        max-width: 657px;
        margin-bottom: 8px;
      }

      .description {
        max-width: 623px;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
      }

      .get-started-button {
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(8px);
        margin: 0 auto;
        padding: 16px 24px;
        border: none;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .content {
      padding: 81px 30px 98px;

      .get-started-section {
        .title {
          font-size: 32px;
          line-height: 35.04px;
          margin-bottom: 16px;
        }

        .description {
          font-size: 16px;
          line-height: 26px;
          text-align: center;
        }
      }
    }
  }
`;

const ContentContainer = styled.section`
  .content {
    max-width: 1440px;
    margin: auto;
    padding: 81px 151px 101px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 81px 60px 101px;
    }


    .pp-list {
      padding-left: 0;

      &.inner {
        margin-top: 8px;
        padding-left: 30px;
        list-style-position: inside;
      }

      ul {
        list-style-type: disc;
      }

      &.sub {
        list-style-type: circle;
      }
    }

    .pp-list-outer {
      margin-bottom: 40px;
      list-style-position: inside;

      &::marker {
        font-size: 32px;
        font-weight: bold;
      }
    }

    .pp-list-header {
      font-family: "Founders Grotesk";
      font-style: normal;
      font-weight: bolder;
      font-size: 32px;
      line-height: 38px;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 8px;
      display: inline-block;
      line-height: 1.5;

      @media only screen and (max-width: 768px) {
        font-size: 24px;
      }
    }

    .pp-list-copy {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.7);
      opacity: 0.8;
      margin-bottom: 12px;

      &.top {
        margin-top: 10px;
      }

      b {
        color: rgba(0, 0, 0, 0.8);
      }
    }

    .pp-list-sub {
      padding-left: 0;
      counter-reset: item;
    }

    .pp-list-header {
      font-family: "Founders Grotesk";
      font-weight: 500;
      font-size: 24px;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 8px;
      display: inline-block;

      @media only screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    li > b {
      color: rgba(0, 0, 0, 1);
    }

    .pp-list-outer-sub {
      margin-bottom: 16px;

      ul ul {
        li {
          list-style-type: circle;
        }
      }
    }

    ol > li {
      counter-increment: item;
    }

    ol.pp-list-sub > li {
      display: block;
    }

    ol.pp-list-sub > li:before {
      font-size: 24px;
      font-weight: 500;
      content: counters(item, ".") ". ";

      @media only screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    .pp-table {
      margin: 16px auto;
      border: 1px solid;

      th, td {
        border: 1px solid;
        padding: 6px;
        font-size: 14px;
      }
    }


    .image-container {
      display: flex;
      gap: 16px;

      .pp-image {
        width: 100%;
        height: 100%;
        max-width: 512px;
      }

      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 48px 30px;
    }
  }
`;